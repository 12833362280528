import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ihi2,ihi3,ihi4,ihi5,ihi6,ihi7,ihi8,ihi9,ihi10,ihi11,ihi12,ihi13, alixdrycleaning, alteration, shirtservice, weddingdress, duvet, curtain, household, bags, watch, satisfaction, a1 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../config";
import PricingList from "../pricingList";
import FAQ from "../../pages/faq";
import SectionThree from "./sectionThree";

function SectionTwo () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`; 
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const shop_url = window.location.href;
  const [areaCovered, setAreaCovered] = useState<any | null>({});
  let shopURL = window.location.href;
  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };
  
  return (
    <>
        {shop_url!.includes("alixdrycleaning") && (
            <section className="review-section-new">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>A Trusted Choice for Dry Cleaning Services</h3>
                            <p className="pt-2">Expert in Dry Cleaning - Bedding - Wash, Dry & Fold, Ironing, Alteration, Repair and more.</p>
                            <div className="review-info pt-3 pb-1">
                                <div className="review-star-new">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <p>
                                        <a className="text-white" href="#">Read more Reviews</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}
        <section id="t11hiw">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-h2 mb-4">How It Works</h2>
                        <h3 className="section-h2">Fast, Easy, and Hassle-Free</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="hiw-grid text-center">
                            <img src={ihi2} alt=""/>
                            <div className="hiw-desc">
                                <span>1.</span>
                                {shopURL?.includes("claphamdrycleaners") ? (
                                    <p>Drop your bag in the above mentioned Parcel Pickup and Drop-off Services.</p>
                                ) : (
                                    <p>Choose <strong>where</strong> and <strong>when</strong> you’d like us to collect and deliver your laundry or items.</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="hiw-grid text-center">
                            <img src={ihi3} alt=""/>
                            <div className="hiw-desc">
                                <span>2.</span>
                                {shopURL?.includes("claphamdrycleaners") ? (
                                    <p>We’ll get your bag, invoice you and clean your items according to your requirements.</p>
                                ) : (
                                    <p>We’ll <strong>collect</strong> your bag, <strong>invoice</strong> you and <strong>clean</strong> your items according to your requirements</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="hiw-grid text-center">
                            <img src={ihi4} alt=""/>
                            <div className="hiw-desc">
                                <span>3.</span>
                                {shopURL?.includes("claphamdrycleaners") ? (
                                    <p>We'll send your items in the same Parcel Pickup and Drop-off Services, within the time required per service.</p>
                                ) : (
                                    <p>We deliver your items, <strong>cleaned</strong> within <strong>48hrs</strong> or, within the time required per service.</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-5">
                        <p style={{display: 'inline-block'}}><a href="#postForm" className="btn hover-btn booknow-new" id="book_now_btn">Book now</a></p>
                    </div>
                </div>
            </div>
        </section>
        <section className="ihrsecbl-bgwhite pb-0 d-none">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-h2">What Our Client Say</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                        }}
                        className="home-slider"
                    >
                    {homedata?.theme_3?.home?.section_12?.review_card.map(
                    (items: any,index: number) => {
                        return (
                        <SwiperSlide>
                            <div className="sh11-testimonial-card" key={index}>
                            <div className="sh11-profile-user">
                                <a className="sh11-profile-user-img">
                                <img src={items.reviewer_pic} className="rounded-circle" />
                                </a>
                                <div className="sh11-profile-user-info">
                                <h6>
                                    <a>{items.reviewer_name}</a>
                                </h6>
                                <div className="sh11-profile-rating">
                                    <span className="secondary-text">
                                    {Array.from({ length: 5 }, (_, i) => (
                                        <i
                                        key={i}
                                        className={`fas fa-star ${i < items.rating ? 'text-secondary' : 'text-secondary text-lightg'}`}
                                        ></i>
                                    ))}
                                    </span>
                                    <span>{items.rating}</span>
                                </div>
                                </div>
                            </div>
                            <h5>{items.review_highlight}</h5>
                            <p>{items.review}</p>
                            </div>
                        </SwiperSlide>
                        );
                    }
                    )}
                    </Swiper>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-2">
                            <img src={ihi5} alt="" />
                            <p>"Best Digi Dry Cleaner"</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-2">
                            <img src={ihi6} alt="" />
                            <p>"For A Hassle-free Life"</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-2">
                            <img src={ihi7} alt="" />
                            <p>"Britain's Best Delivery Service"</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-2">
                            <img src={ihi8} alt="" />
                            <p>"4.7 average customer rating"</p>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
        {shop_url!.includes("alixdrycleaning") && (
            <section className="quick-service-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-h2 section-h2w">We offer quick and effective dry cleaning & alteration services</h2>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="d-center">
                                <img className="pr-3" src={bags} alt="" />
                                <div className="text-start">
                                    <p><strong className="mt-2">Free Collection & Delivery</strong></p>
                                    <p className="pt-2">With friendly drivers</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="d-center">
                                <img className="pr-3" src={watch} alt="" />
                                <div className="text-start">
                                    <p><strong className="mt-2">48 Hr Turnaround</strong></p>
                                    <p className="pt-2">On nearly all items</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="d-center">
                                <img className="pr-3" src={satisfaction} alt="" />
                                <div className="text-start">
                                    <p><strong className="mt-2">Satisfaction Guaranteed</strong></p>
                                    <p className="pt-2">Or we'll re-clean for free</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}
        <section>
        {shop_url!.includes("alixdrycleaning") ? (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-h2">Our Services</h2>
                        </div>
                    </div>
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        pagination={{
                        el: ".swiper-pagination",
                        clickable: true,
                        }}
                        breakpoints={{
                        640: {
                            slidesPerView: 4,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        1024: {
                            slidesPerView: 4,
                        },
                        }}
                        className="slider-adrs"
                    >
                        <SwiperSlide>
                            <div className="card-howwe p-0">
                                <div className="card-howwe-img">
                                    <img src={alixdrycleaning} alt="" />
                                </div>
                                <div className="p-3">
                                    <h3 style={{color: 'rgb(46, 54, 57)'}}>Dry Cleaning</h3>
                                    <p style={{color: 'rgb(109, 122, 128)'}}>
                                        Our dry cleaning service uses advanced techniques to gently cleanse all types of fabrics, leaving them fresh and spotless.
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-howwe p-0">
                                <div className="card-howwe-img">
                                    <img src={alteration} alt="" />
                                </div>
                                <div className="p-3">
                                    <h3 style={{color: 'rgb(46, 54, 57)'}}>Alterations & Repairs</h3>
                                    <p style={{color: 'rgb(109, 122, 128)'}}>
                                        From simple hern adjustments to complex resizing, our alteration services ensure your clothes fit perfectly. Our skilled tailors handle each piece with precision and care, making sure every garment meets your expectations.
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-howwe p-0">
                                <div className="card-howwe-img">
                                    <img src={shirtservice} alt="" />
                                </div>
                                <div className="p-3">
                                    <h3>Shirt Service</h3>
                                    <p style={{color: 'rgb(109, 122, 128)'}}>
                                        Experience the joy of impeccably cleaned and crisply pressed shirts, returned to you on a hanger or folded for your convenience.
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-howwe p-0">
                                <div className="card-howwe-img">
                                    <img src={weddingdress} alt="" />
                                </div>
                                <div className="p-3">
                                    <h3>Wedding Dresses</h3>
                                    <p style={{color: 'rgb(109, 122, 128)'}}>
                                        Specializing in delicate fabrics, we provide expert cleaning and alterations to keep your formal attire looking its best.
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-howwe p-0">
                                <div className="card-howwe-img">
                                    <img src={duvet} alt="" />
                                </div>
                                <div className="p-3">
                                    <h3>Duvet and Bed Linen</h3>
                                    <p style={{color: 'rgb(109, 122, 128)'}}>
                                        Enjoy the comfort of fresh, clean duvet and bed linen blankets. Our meticulous cleaning process ensures your bedding is hygienic and inviting.
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-howwe p-0">
                                <div className="card-howwe-img">
                                    <img src={curtain} alt="" />
                                </div>
                                <div className="p-3">
                                    <h3>Curtain</h3>
                                    <p style={{color: 'rgb(109, 122, 128)'}}>
                                        Restore the beauty of your home with our expert curtain cleaning services. We delicately clean and hand-finish your curtains, ensuring they look good as new.
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-howwe p-0">
                                <div className="card-howwe-img">
                                    <img src={household} alt="" />
                                </div>
                                <div className="p-3">
                                    <h3>HouseHold Textiles</h3>
                                    <p style={{color: 'rgb(109, 122, 128)'}}>
                                        Enhance the freshness of your home with our household textile cleaning services. We clean and press your household textiles to perfection.
                                    </p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    {/* <PricingList /> */}
                    <div className="col-md-12 text-center mt-5">
                        <p style={{display: 'inline-block', minWidth: '150px'}}><Link to={"/pricing"} className="btn hover-btn pricing-new" id="book_now_btn">Pricing</Link></p>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                        <p style={{display: 'inline-block', minWidth: '150px'}}><a href="#postForm" className="btn hover-btn booknow-new" id="book_now_btn">Book now</a></p>
                    </div>
                    {/* <div className="swiper-slide swiper-slide-active" style={{width: '312.75px', marginRight: '15px'}}>
                        
                    </div> */}
                </div>
            ) : (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-h2">Pricing</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="ihr-sec-grid-3">
                                <img src={ihi9} alt="" />
                                <h3>Shirts</h3>
                                <p>Washed and Pressed</p>
                                <strong>From £2.50</strong>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="ihr-sec-grid-3">
                                <img src={ihi10} alt="" />
                                <h3>Day Dress</h3>
                                <p>Dry Clean</p>
                                <strong>From £11.50</strong>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="ihr-sec-grid-3">
                                <img src={ihi11} alt="" />
                                <h3>Service Wash</h3>
                                <p>Wash, Dry and Fold</p>
                                <strong>From £16.95</strong>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="ihr-sec-grid-3">
                                <img src={ihi12} alt="" />
                                <h3>Bedding</h3>
                                <p>Bed Set (Wash and Press)</p>
                                <strong>From £14.00</strong>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
        {shop_url!.includes("alixdrycleaning") ? (
            <>
                <section className="ihrsecbl">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    {/* <img src={ihi13} alt="" className="mb-3" /> */}
                                    <h2 className="section-h2 section-h2w">Alix Dry Cleaning Centre | SINCE 1980</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <>
                                    <div className="ihr-sec-grid-4">
                                        <p>Welcome yo <strong>Alix Dry Cleaning Centre</strong>, Edmonton's N18 first on-demand dry cleaning service! For over 40 years, we've perfected the art of garment care, extending our trusted services beyond Edmonton to surrounding communities.</p>
                                    </div>
                                    <div className="ihr-sec-grid-4">
                                        <h3>WHY CHOOSE US?</h3>
                                        <p><strong>Master Craftsmanship:</strong> Every garment is treated with meticulous care by our expert cleaners, ensuring world-class quality.</p>
                                        <p><strong>Convenience Redefined:</strong> Free pickup & delivery tailored to *your* schedule.</p>
                                        <p><strong>Community Trusted:</strong> A legacy of reliability, excellence, and personalized service.</p>
                                    </div>
                                </>
                                <div className="ihr-sec-grid-4">
                                    <h3>OUR LOCATIONS</h3>
                                    <p><strong>Main Shop:</strong> 2 Sterling Way, Edmonton, N18 2XZ</p>
                                    <p><a href="tel:02088073692" className="m-2">020 8807 3692</a> | <a href="https://maps.app.goo.gl/36FqEmNhzUGYHeG79" target="_blank" className="m-2"><i className="fas fa-map-marker-alt"></i> Locate</a></p>
                                </div>
                                <div className="ihr-sec-grid-4">
                                    <h3>Areas We Serve</h3>
                                    <p>Chingford, Edmonton Green, Seven Sisters, Haringey, Lower Edmonton, and beyond!</p>
                                    <p><Link to={"/areaCovered"} className="btn btn-lg hover-btn">Areas Covered</Link></p>
                                </div>
                                <div className="ihr-sec-grid-4">
                                    <h3>THE EXPERT DIFFERENCE</h3>
                                    <p><strong>Premium Quality:</strong> Advanced techniques for delicate fabrics and stubborn stains.</p>
                                    <p><strong>Eco-Friendly Solutions:</strong> Safe for your clothes and the planet.</p>
                                    <p><strong>Alterations & Repairs:</strong> Perfect fits and flawless fixes by skilled tailors.</p>
                                </div>
                                <div className="ihr-sec-grid-4">
                                    <h3>FLEXIBLE. FAST. STRESS-FREE</h3>
                                    <p>Need a last-minute refresh? We offer same-day processing*! Please visit our shop for same-day service.</p>
                                    <p><a href="https://maps.app.goo.gl/36FqEmNhzUGYHeG79" target="_blank" className="btn hover-btn"><i className="fas fa-map-marker-alt"></i> Find us on Google Maps</a></p>
                                </div>
                                <div className="ihr-sec-grid-4">
                                    <h3>YOUR GARMENTS DESERVE THE BEST</h3>
                                    <p>Join thousands of satisfied customers who trust <strong>Alix Dry Cleaning Centre</strong> for: </p>
                                    <p>✔ Wedding gowns & suits</p>
                                    <p>✔ Luxury fabrics & designer wear</p>
                                    <p>✔ Everyday wardrobe essentials</p>
                                </div>
                                <div className="ihr-sec-grid-4">
                                    <h3>READY TO EXPERIENCE ALIX DRY CLEANING</h3>
                                    <p>Book your free pickup</p>
                                    <p style={{display: 'inline-block'}}><a href="#postForm" className="btn hover-btn booknow-new" id="book_now_btn">Book now</a></p>
                                    <p>*Serving Edmonton N18 & Neighbourhoods with Pride Since 1980.*</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-center grad">
                                <img src={a1} alt="Alix" height={300} width={350} />
                            </div>
                            <div className="col-md-6">
                                <h2 className="p-2">Effortless laundry every time</h2>
                                <p className="pt-3 p-2">✔ Convenient</p>
                                <p className="pt-3 p-2">✔ Reliable</p>
                                <p className="pt-3 p-2">✔ and Ready when you</p>
                                <p className="pt-4 p-2"><a href="#postForm" className="btn hover-btn booknow-new" id="book_now_btn">Book now</a></p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h5 className="text-center">Frequently Asked Questions</h5>
                    <FAQ />
                </section>
                <SectionThree/>
            </>
        ) : (
            <section className="ihrsecbl">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                {/* <img src={ihi13} alt="" className="mb-3" /> */}
                                <h2 className="section-h2 section-h2w">The best Dry Cleaning delivered <br/>directly to your door</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {shopURL?.includes("claphamdrycleaners") ? (
                                <>
                                    <div className="ihr-sec-grid-4">
                                        <p>We are a network of the very best dry cleaners. </p>
                                        <p>We provide the finest dry cleaning and laundry services, combined with exceptional customer service.  </p>
                                    </div>
                                    <div className="ihr-sec-grid-4">
                                        <h3>DRY CLEANING DELIVERY - HOW DOES IT WORK?</h3>
                                        <p>The service is simple: drop your items in the mentioned Parcel Pickup and Drop-off Services, we professionally clean or repair them, and send them back to the same Parcel Pickup and Drop-off Services in less than 48 hours.</p>
                                        <p>We offer flexible time slots, allowing us to work around your schedule and deliver your clothes beautifully washed, pressed and finished exactly when you need them.</p>
                                        <p>If you’re especially pressed for time, you can drop or collect the items directly from our shop</p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="ihr-sec-grid-4">
                                        <p>We are a network of the very best dry cleaners. </p>
                                        <p>We provide the finest dry cleaning and laundry services, combined with exceptional customer service and convenient collection and delivery suited perfectly to your schedule.  </p>
                                    </div>
                                    <div className="ihr-sec-grid-4">
                                        <h3>DRY CLEANING DELIVERY - HOW DOES IT WORK?</h3>
                                        <p>The service is simple: we collect your items, professionally clean or repair them, and deliver them back to your doorstep in less than 48 hours.</p>
                                        <p>We offer flexible collection and delivery time slots, allowing us to work around your schedule and deliver your clothes beautifully washed, pressed and finished exactly when you need them.</p>
                                        <p>If you’re especially pressed for time, you can opt for same day laundry delivery on items collected before 10am.</p>
                                    </div>
                                </>
                            )}
                            <div className="ihr-sec-grid-4">
                                <h3>EXPERT DRY CLEANERS</h3>
                                <p>Founded in 2014, we are the first on demand laundry service in London, and has since expanded its horizons to operate in cities across
                                the UK, as well as in New York City.</p>
                                <p>We work with a network of the best local dry cleaners, ensuring the best quality dry cleaning, repairs, alterations and more.</p>
                                <p>Every item is treated with care and respect by our master cleaners, who provide world-class cleaning services time and time again. </p>
                                <p>If you’re looking to find the best dry cleaner near you, then look no further than us. </p>
                            </div>
                            <div className="ihr-sec-grid-4">
                                <h3>OUR LOCATIONS</h3>
                                <ul>
                                    <>
                                    {Object.keys(areaCovered).length > 0 &&
                                    popAreadCovered(areaCovered?.data.data.areas_covered)}
                                    
                            {Object.keys(areaCovered).length > 0 &&
                                    popAreadCovered2(areaCovered?.data.data.areas_covered)}
                                    </>
                                </ul>
                            </div>
                            <div className="ihr-sec-grid-4">
                                <h3>EXPERT LAUNDRY SERVICES</h3>
                                <p>We have a range of premium laundry services on offer, from <strong>dry cleaning for your favourite suits, shirts and dresses, </strong>to expert<strong> tailoring and repairs</strong> on any of your garments, and everything in between.</p>
                                <p>For more information, visit our <strong><a href="">services page, </a></strong> or reach out to our friendly Customer Care team with your queries.</p>
                            </div>
                            <div className="ihr-sec-grid-4">
                                <h3>LAUNDRY AND DRY CLEANING BLOG</h3>
                                <p>Follow our <strong><a href={blogUrl}> blog</a></strong> to stay up to date with industry and sustainability news and keep up with the exciting things we're doing as a
                                    business. You can also find plenty of tips and tricks and in-depth step by step guides to help you navigate all kinds of laundry and dry cleaning on
                                    our blog.</p>
                            </div>
                        </div>
                    </div>
                    <div className="ihisecin">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h2 className="section-h2 section-h2w">ARE MY CLOTHES SAFE WITH<br/>YOUR SERVICE?</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3 mt-4">
                                <div className="ihr-sec-grid-5">
                                    <div className="ihr-sec-count">71,654</div>
                                    <p>Items cleaned in March.</p>
                                </div>
                            </div>
                            <div className="col-md-3 mt-4">
                                <div className="ihr-sec-grid-5">
                                    <div className="ihr-sec-count">121</div>
                                    <p>Reports of damage to an item.</p>
                                </div>
                            </div>
                            <div className="col-md-3 mt-4">
                                <div className="ihr-sec-grid-5">
                                    <div className="ihr-sec-count">99.9%</div>
                                    <p>Items cleaned without an issue.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h2 className="section-h2 section-h2w">OUR PROMISE TO YOU</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="ihr-sec-grid-6">
                                <p>We do everything to minimise any risk, but on the very rare occasion an item is damaged, we’ll hold our hands up and admit the mistake and
                                either fix it or compensate you for any damage or loss</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}
    </>
  );
}

export default SectionTwo;
