import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./header.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import "./header.css";
import Notification from "./../notification";
import Profile from "../profile";
import QuickOrderPopup from "../quickOrderPopup";
import CartSummary from "../cartSummery";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { log } from "console";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { ToastContainer } from "react-toastify";
import Pusher from "pusher-js";
import {
  mobilemenu,
  moreitems,
  quickorder,
  myprofile,
  logout,
  phonecall,
  cart,
  loguser,
} from "../../assets/img";
import ReactGA from "react-ga";
import { getHomeJsonList } from "../../redux/Actions";
import { checkShopListFlow } from "../../utility";

const TRACKING_ID = "UA-265690492-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const getUser = () => {
  const token: any = localStorage.getItem("userDetails");
  return token ? JSON.parse(token) : "";
};

function HeaderOne() {
  const dispatch = useDispatch<any>();
  const [changePopup, setChangePopup] = useState(false);
  const [changePopupProfile, setChangePopupProfile] = useState(false);
  const [quickBookPopup, setQuickBookPopup] = useState(false);
  const [cartSummary, setCartSummary] = useState(false);
  const [userName, setUserName] = useState<any>("");
  const [userToken, setUserToken] = useState();

  const [cartValue, setCartValue] = useState<any>([]);
  const [pusherKey, setpusherKey] = useState<any>("");
  const state: any = useSelector<any>((state) => state);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const { cartCount }: any = useSelector<any>((state) => state);
  const [notifyCount, setNotifyCount] = useState<any>([]);
  const [cartCounts, setCartCount] = useState<number>(0);
  const [finalTotalPrice, setFinalTotalPrice] = useState<number>(0);
  const name = localStorage.getItem("username");
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);

  //

  // useEffect(()=>{
  //   const cartInformationData = selectedCategories?.filter((item: any) => {
  //     return item?.sub_categories?.some(
  //       (subItem: any) => parseInt(subItem?.quantity) > 0
  //     );
  //   });
  //   setCartValue(cartInformationData);
  // },[selectedCategories])

  // useEffect(() => {
  //   if (state) {
  //     // const { data = { data: {} } } = state.userDetails;
  //     const data = JSON.parse(localStorage.getItem('userDetails')!);

  //     if (data) {
  //       const { user_info = {} } = data;
  //       const { name = "" } = user_info;
  //
  //       // const token = getToken();
  //       setUserToken(name);
  //       setUserName(name);
  //     }
  //   }
  // }, [state]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.href]);

  const selectedCategories: any = useSelector<any>(
    (state) => state.userSelectedCategories
  );

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const base_url = process.env.REACT_APP_BACKEND_URL;
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  const shopurl = 'https://bestatlaundry.com/laundry'
  const activeUrl = window.location.pathname;
  const [settings, setSettings] = useState<any>({});

  useEffect(() => {
    dispatch(getHomeJsonList());
  }, []);

  useEffect(() => {
    if (state.userDetails.data) {
      const user_info = state.userDetails.data?.user_info;
      const { name = "" } = user_info;
      // const token = getToken();
      setUserToken(name);
      setUserName(name);
    }

    const selectedMainCategory = selectedCategories?.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });

    let finalTotalPrice = 0;
    let itemsCount = 0;

    if (selectedMainCategory.length) {
      selectedMainCategory?.map((item: any) => {
        const subcategoryList = item?.sub_categories?.filter(
          (subItem: any) => parseInt(subItem.quantity) > 0
        );
        itemsCount = itemsCount + subcategoryList?.length;
        subcategoryList?.map((subcategory: any) => {
          finalTotalPrice =
            finalTotalPrice +
            parseInt(subcategory?.quantity) *
              parseFloat(subcategory?.total_price);
        });
      });

      // cartInformationData?.map((item: any) => {
      //   const subcategoryList = item?.sub_categories?.filter(
      //     (subItem: any) => parseInt(subItem.quantity) > 0);
      // });
    }
    setFinalTotalPrice(finalTotalPrice);
    setCartCount(itemsCount);
  }, [state]);

  const navigate = useNavigate();
  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };
  useEffect(() => {
    AOS.init();
    // AOS.refresh();
  }, []);

  const [sticky, setSticky] = useState("");

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 100 ? "fixed" : "";
    setSticky(stickyClass);
  };

  const fixedheader = `header ${sticky}`;
  function addNotify() {
    setNotifyCount((prevState: any) => [...prevState, "1"]);
  }

  let oldNotify = "";

  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      const userInfo = JSON.parse(localStorage.getItem("userDetails")!);

      const { data } = userInfo;

      if (data != null) {
        // const notifyLocalCount = localStorage.getItem('notifyCount');

        const interest = data.user_info.notification_interest_list;

        if (interest[1] != undefined) {
          const intrst = interest[1];
          var pusher = new Pusher(pusherKey, {
            cluster: "eu",
          });

          var channel = pusher.subscribe(intrst);
          channel.bind("my-event", function (datas: any) {
            let event = datas.order;
            // alert(event.title);
            // toast.dismiss();

            // setNotifyCount(notifyCount);

            // var newArray = notifyCount.concat('1');

            //

            // const rand = Math.random();

            // setNotifyCount(notifyCount.concat(rand));
            // setNotifyCount((prevState: any) => ([...prevState, '1']))

            if (oldNotify != event.order_id) {
              addNotify();
              oldNotify = event.order_id;
            }

            // localStorage.setItem('notifyCount', `${parseInt(notifyLocalCount!) + 1}`);

            // setNotifyCount(notifyCount + 1);
            // toast(<div>{event.title}<br />{event.body}</div>, { autoClose: false });
          });
        }
      }

      if (data) {
        const user_info = data?.user_info;
        const { name = "" } = user_info;
        // const token = getToken();
        setUserToken(name);
        setUserName(name);
      }

      // setUserToken(token);
    }
    let handler = (event: { target: Node | null }) => {
      if (menuRef.current) {
        if (!menuRef.current.contains(event.target)) {
          setChangePopupProfile(false);
          setCartSummary(false);
        }
      }
    };
    document.addEventListener("mousedown", function name(params: any) {
      handler(params);
    });
    document.addEventListener("scroll", function name() {
      // AOS.init();
      AOS.refresh();
    });
  }, []);

  const location = useLocation();
  let newurl = location.pathname;
  let splitURL: any = newurl.toString().split("/");

  useEffect(() => {
    sidebarClose();
    cartboxClose();
  }, [location]);

  var sidebar = document.getElementById("sidebar");
  var overlay = document.getElementById("overlay");
  var cartoverlay = document.getElementById("cd-shadow-layer");
  var cdcart = document.getElementById("cd-cart");

  const cartboxClose = () => {
    cdcart?.classList.remove("speed-in");
    cartoverlay?.classList.remove("is-visible");
  };
  const cartboxOpen = () => {
    cdcart?.classList.add("speed-in");
    cartoverlay?.classList.add("is-visible");
  };

  const sidebarClose = () => {
    sidebar?.classList.remove("active");
    overlay?.classList.remove("active");
  };
  const sidebarOpen = () => {
    sidebar?.classList.add("active");
    overlay?.classList.add("active");
  };

  function openQuickOrderPopup() {
    setQuickBookPopup(!quickBookPopup);
    localStorage.setItem("disableLoader", "yes");
  }
  function closeQuickOrderPopup() {
    setQuickBookPopup(false);
    localStorage.removeItem("disableLoader");
  }

  useEffect(()=>{
    if(settingsData != undefined){
      setSettings(settingsData);
    }
  },[settingsData])

  return (
    <div>
      {jsonData && (
        <style type="text/css">
          {` 
            :root {
              --primary-color: #${jsonData?.theme_1?.home?.header?.primary_color};
              --secondary-color: #${jsonData?.theme_1?.home?.header?.secondary_color};
              --nav-color: #${jsonData?.theme_1?.home?.header?.nav?.menu_font_color};
              --nav-color-hover: #${jsonData?.theme_1?.home?.header?.nav?.menu_font_color_hover};
              --nav-color-active: #${jsonData?.theme_1?.home?.header?.nav?.menu_font_color_active};
              --btn1-color:#${jsonData?.theme_1?.home?.header?.btn_group?.btn1?.btn_color};
              --btn1-border_color:#${jsonData?.theme_1?.home?.header?.btn_group?.btn1?.border_color};
              --btn1-bg_color:#${jsonData?.theme_1?.home?.header?.btn_group?.btn1?.btn_bg_color};
          }
          `}
        </style>
      )}
      <header
        className={fixedheader}
        style={{
          background: `#${jsonData?.theme_1?.home?.header?.nav?.nav_bg_color}`,
        }}
      >
        <div className="header-top">
          <div className="container">
            <div className="row header-nav">
              <div className="col-md-auto col-sm-12">
                <div className="logo">
                  <span
                    id="sidebarCollapse"
                    className="d-block d-md-none"
                    onClick={sidebarOpen}
                  >
                    <img src={mobilemenu} className="img-fluid" alt="" />
                  </span>
                  <Link to="/">
                    <img
                      id="site_logo"
                      src={`${
                        Object.keys(settings).length > 0 &&
                        settings.WebsiteSettings.logo_path
                      }${
                        Object.keys(settings).length > 0 &&
                        settings.WebsiteSettings.style_logo_en
                      }`}
                      // src={`${Object.keys(settings).length > 0 && settings.WebsiteSettings.style_logo_en}`}
                      alt=""
                    />
                  </Link>
                  <span className="mob-user hidden-md dropdown d-block d-md-none">
                    <a className="more-items"
                        data-bs-toggle="dropdown"
                        href="#"
                      ></a>
                    <ul
                      role="menu"
                      className="dropdown-menu"
                      aria-labelledby="drop1"
                    >
                      {settingsData?.quick_order_mode != 0 &&
                      <li>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#quickordermodal"
                        >
                          <img src={quickorder} className="img-fluid" alt="" />
                          Quick Order
                        </a>
                      </li>
                      }
                      {!userToken && (
                        <li className="login">
                          <Link
                            to="/login"
                            style={{
                              background: `#${jsonData?.theme_1?.home?.btn_group?.btn1?.bg_color}`,
                              color: `#${jsonData?.theme_1?.home?.btn_group?.btn1?.color}`,
                              borderColor: `#${jsonData?.theme_1?.home?.btn_group?.btn1?.border_color}`,
                            }}
                          >
                            <img src={myprofile} className="img-fluid" alt="" />
                            {jsonData?.theme_1?.home?.header?.btn_group?.btn1?.text}
                          </Link>
                        </li>
                      )}
                      {userToken && (
                        <li className="dropdown menu-hover-dropdown">
                          <a
                            className="menuitem"
                            href="#"
                            onClick={(e) => handleLogout(e)}
                          >
                            <img src={logout} className="img-fluid" alt="" />
                            Log Out
                          </a>
                        </li>
                      )}
                    </ul>
                  </span>
                </div>
              </div>
              <div className="col-md col-sm-12">
                <div
                  className="d-none d-md-flex align-items-center h-100"
                  ref={menuRef}
                >
                  <ul className="header-link">
                    <li className="menu-hover">
                      {activeUrl === "/" ? (
                        <NavLink className="nav-bar-link active" to="/">
                          Home
                        </NavLink>
                      ) : (
                        <NavLink className="nav-bar-link" to="/">
                          Home
                        </NavLink>
                      )}
                    </li>
                    {!checkShopListFlow() && 
                      <li className="menu-hover">
                        {activeUrl === "/pricing" ? (
                          <NavLink className="nav-bar-link active" to="/pricing">
                            Pricing
                          </NavLink>
                        ) : (
                          <NavLink className="nav-bar-link" to="/pricing">
                            Pricing
                          </NavLink>
                        )}
                      </li> 
                    }
                    <li className="menu-hover">
                      {activeUrl === "/areaCovered" ? (
                        <NavLink
                          className="nav-bar-link active"
                          to="/areaCovered"
                        >
                          Area Covered
                        </NavLink>
                      ) : (
                        <NavLink className="nav-bar-link" to="/areaCovered">
                          Area Covered
                        </NavLink>
                      )}
                    </li>
                    {checkShopListFlow() && splitURL.length < 3 &&
                      <li className="faq-menu menu-hover">
                        <a target="_blank" href={shopurl} className="signup-m">List Your Laundry</a>
                      </li>
                    }
                    <li className="faq-menu menu-hover">
                    <a target="_blank" href={blogUrl}>Blog</a>
                  </li>
                    {userToken && (
                      <li className="dropdown menu-hover-dropdown login-link cart-list dropdown-notification">
                        <Link
                          to="#"
                          id="drop1"
                          data-bs-toggle="dropdown"
                          className="dropdown-toggle"
                        >
                          <i
                            className="fa fa-bell"
                            aria-hidden="true"
                            onClick={() => setChangePopup(!changePopup)}
                          ></i>
                          {/* <span id="notify_total_count" className="count-blk">
                            {notifyCount.length}
                          </span> */}
                        </Link>
                        <div className="dropdown-menu">
                          <Notification />
                        </div>
                      </li>
                    )}

                    {userToken && (
                      <li className="dropdown menu-hover-dropdown">
                        <Link
                          to="#"
                          id="drop1"
                          data-bs-toggle="dropdown"
                          className="dropdown-toggle"
                          role="button"
                          onClick={() =>
                            setChangePopupProfile(!changePopupProfile)
                          }
                        >
                          {`Hi ${name || userName}!`}
                          <b className="caret"></b>
                        </Link>
                        <div className="dropdown-menu">{<Profile />}</div>
                      </li>
                    )}
                    {settingsData?.quick_order_mode != 0 &&
                    <li
                      className="login menu-hover"
                    >
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#quickordermodal"
                      >
                        Quick Order
                      </Link>
                    </li>
                    }
                  </ul>
                  {activeUrl != '/checkout' &&
                  <ul className="header-link-right">
                    {jsonData?.theme_1?.home?.header?.nav.contact?.contact_is_enable ===
                    true ? (
                      <>
                        <li className="d-none d-xl-inline-block">
                          <a
                            href={`tel:${jsonData?.theme_1?.home?.header?.nav.contact?.number}`}
                          >
                            <i className="me-2 uil uil-phone-volume"></i>
                            <span
                              style={{
                                color: `#${jsonData?.theme_1?.home?.header?.nav.contact?.contact_color}`,
                              }}
                            >
                              {jsonData?.theme_1?.home?.header?.nav.contact?.number}
                            </span>
                          </a>
                          {settingsData?.WebsiteSettings?.site_title_en.includes('Sasian') && (
                            <a href="mailto:sasianironing@gmail.com" className="text-white d-block"><i className="me-2 uil uil-envelope"></i>sasianironing@gmail.com</a>
                          )}
                        </li>
                      </>
                    ) : null}

                    <li
                      style={{
                        background: `#${jsonData?.theme_1?.home?.header?.nav?.cart_section?.cart_bg_color}`,
                      }}
                      className="header-box"
                      onClick={cartboxOpen}
                    >
                      <img
                        className="cart-icon p-1"
                        style={{
                          background: `#${jsonData?.theme_1?.home?.header?.nav?.cart_section?.cart_icon_bg_color}`,
                        }}
                        src={cart}
                        alt=""
                      />
                      <span
                        style={{
                          color: `#${jsonData?.theme_1?.home?.header?.nav?.cart_section?.cart_value_color}`,
                        }}
                      >
                        {settingsData?.WebmasterSettings?.currency}
                        {finalTotalPrice.toFixed(2)}
                      </span>
                      <span
                        style={{
                          color: `#${jsonData?.theme_1?.home?.header?.nav?.cart_section?.cart_value_color}`,
                          background: `#${jsonData?.theme_1?.home?.header?.nav?.cart_section?.cart_icon_bg_color}`,
                        }}
                        id="cart_total_count"
                        className="count-blk"
                      >
                        {cartCounts}
                      </span>
                    </li>
                    {!userToken &&
                      jsonData?.theme_1?.home?.header?.btn_group?.btn1
                        ?.btn1_is_enable === true && (
                        <>
                          <li
                            className="header-auth header-box"
                            style={{
                              border: `2px solid #${jsonData?.theme_1?.home?.header?.btn_group?.btn1?.border_color}`,
                              background: `#${jsonData?.theme_1?.home?.header?.btn_group?.btn1?.btn_bg_color}`,
                            }}
                          >
                            <Link
                              to="/login"
                              style={{
                                color: `#${jsonData?.theme_1?.home?.header?.btn_group?.btn1?.btn_color}`,
                              }}
                            >
                              <img src={loguser} alt="" />
                              {jsonData?.theme_1?.home?.header?.btn_group?.btn1?.text}
                            </Link>
                          </li>
                        </>
                      )}
                  </ul>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <nav
        id="sidebar"
        className="mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar"
        style={{ overflow: "visible" }}
      >
        <div
          id="mCSB_1"
          className="mCustomScrollBox mCS-minimal mCSB_vertical mCSB_outside"
          style={{ maxHeight: "480px" }}
        >
          <div
            id="mCSB_1_container"
            className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
            style={{ position: "relative", top: "0px", left: "0px" }}
            dir="ltr"
          >
            <div id="dismiss" onClick={sidebarClose}>
              <i className="fa fa-arrow-left"></i>
            </div>
            <div className="sidebar-header"></div>
            <ul className="list-unstyled components">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/areaCovered">Area Covered</Link>
              </li>
              {checkShopListFlow() && splitURL.length < 3 &&
                <li>
                  <a target="_blank" href={shopurl} className="signup-m">List Your Laundry</a>
                </li>
              }
            </ul>
          </div>
        </div>
        <div
          id="mCSB_1_scrollbar_vertical"
          className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal mCSB_scrollTools_vertical"
          style={{ display: "none" }}
        >
          <div className="mCSB_draggerContainer">
            <div
              id="mCSB_1_dragger_vertical"
              className="mCSB_dragger"
              style={{
                position: "absolute",
                minHeight: "0px",
                height: "0px",
                top: "0px",
                display: "block",
              }}
            >
              <div
                className="mCSB_dragger_bar"
                style={{ lineHeight: "0px" }}
              ></div>
            </div>
            <div className="mCSB_draggerRail"></div>
          </div>
        </div>
      </nav>
      <div id="overlay" onClick={sidebarClose}></div>
      <div
        className="modal fade"
        id="quickordermodal"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
              <QuickOrderPopup close={closeQuickOrderPopup} />             
          </div>
        </div>
      </div>
      <div id="cd-shadow-layer" onClick={cartboxClose}></div>
      {<CartSummary />}
    </div>
  );
}

export default HeaderOne;
