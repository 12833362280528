import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList } from "../../redux/Actions";
import SampleVideo from "../../images/sample.mp4";
import { appstore, googleplay, ihi14, ihi15, ihi16, ihi17, ro14, ro15, starcraftios,starcraftandroid, alixios, alixandroid  } from "../../assets/img";
import { checkShopListFlow } from "../../utility";

function FooterIhateIroning() {
  const navigate = useNavigate();
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const appStorel =
    Object.keys(settingsData).length > 0 &&
    settingsData.WebmasterSettings.app_store_link;
  const playStore =
    Object.keys(settingsData).length > 0 &&
    settingsData.WebmasterSettings.play_store_link;
  // const facebook =
  //   Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link1;
  // const phone =
  //   Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t3;
  // const email =
  //   Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6;
  const dispatch = useDispatch<any>();

  const [areaCovered, setAreaCovered] = useState<any | null>({});

  const homedata: any = useSelector<any>((state) => state?.homeJsonList);

  useEffect(() => {
    // if (Object.keys(settings).length === 0 && settingsData == undefined) {
    //   axios({
    //     method: "get",
    //     url: `${base_url}/api/settings`,
    //     headers: {
    //       Accept: "application/json",
    //       "Access-Control-Allow-Methods": "GET, POST",
    //     },
    //   })
    //     .then((response) => {
    //       if (response.status != 401) {
    //         setSettings(response.data.data);
    //         dispatch(setSettingsAll(response.data.data));
    //       }
    //     })
    //     .catch(function (error) {});
    // }

    const headers = {
      Accept: "application/json",
    };

    axios
      .get(`${base_url}/api/areas_covered`, {
        headers: headers,
      })
      .then((e) => {
        setAreaCovered({ data: e.data });
      });
  }, []);

  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",");

      return (
        <>
          {cityArr.map((city: any) => (
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dry-cleaners/" + city);
                }}
              >
                {city}
              </a>
            </li>
          ))}
        </>
      );
    });

    return test;
  };
  let shopURL = window.location.href;
  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  const navigateToAreacovered = () => {
    if (checkShopListFlow()) {
      const shopName: any = localStorage.getItem("currentShopName");
      if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());

        navigate(`/shop/${finalResult}/areaCovered`);
      } else {
        navigate("/areaCovered");
      }
    } else {
      navigate("/areaCovered");
    }
  };
  const navigateToTermsAndConditions = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
      const finalResult = shopName
        .trim()
        .replace(/\s(.)/g, (match: any) => match.toUpperCase())
        .replace(/\s/g, "")
        .replace(/^(.)/, (match: any) => match.toLowerCase());
      if (shopName) {
        navigate(`/shop/${finalResult}/termsAndConditions`);
      } else {
        navigate("/termsAndConditions");
      }
    } else {
      navigate("/termsAndConditions");
    }
  };
  const navigateToAboutUs = () => {
    if (checkShopListFlow()) {
      const shopName: any = localStorage.getItem("currentShopName");
      if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());

        navigate(`/shop/${finalResult}/aboutUs`);
      } else {
        navigate("/aboutUs");
      }
    } else {
      navigate("/aboutUs");
    }
  };

  return (
    <div>
        <footer className="footer ftheme11">
            <div className="container">
                <div className="row">
                  <div className="col-md col-sm-12"> 
                    <div className="fldesc py-0">
                        <p>
                          The spring fresh smell, razor sharp pressing, and impeccable cleaning is driven by our expert dry cleaners' passion and pride in what they do. We are the leading on demand laundry service offering convenient dry cleaning services.
                        </p>  
                      </div>            
                  </div>
                  <div className="col-md col-sm-12">
              <div className="fmenu">
                <h4>Quick Links</h4>
                <ul>
                <li>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToAboutUs();
                    }}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    onClick={navigateToTermsAndConditions}
                    style={{ cursor: "pointer" }}
                  >
                    Terms and Conditions
                  </a>
                </li>
                <NavLink to={'/contact-us'} style={{color: '#000'}}>Contact Us</NavLink>
                {/* <li><a href=''>Enquiry</a></li> */}
                {/* <li><a href=''>Call a Schedule</a></li> */}
              </ul>
              </div>
              
              
            </div>
            <div className="col-md col-sm-12">
            <div className="fmenu">
              <h4>Explore</h4>
              <ul>
                <li>
                  <a href="#t11hiw">How It Works</a>
                </li>
                {/* <li><a href=''>Help Center</a></li> */}
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToAreacovered();
                    }}
                  >
                    Locations
                  </a>
                </li>
                {/* <li><a href=''>Carreers</a></li>
                <li><a href=''>Special Requests</a></li> */}
              </ul>
              </div>
            </div>
                </div>
                
                <div className="row">
                    <div className="col-md">
                        <div className="flapp">
                            <span>Download our new app:</span>
                            <ul className="flapplinks"> 
                                <li>
                                {shopURL?.includes("alixdrycleaning") && (  
                                  <div className="bar-code-grid">                                                
                                      <img src={alixios} alt=''/>
                                  </div>
                                )}
                                    <a target="_blank" href={appStorel}>
                                        <img src={ihi16} alt="" />
                                    </a>
                                    {shopURL?.includes("starcraftdrycleaners") && (  
                                      <div>
                                      <img src={starcraftios} alt="" className="qr11"/>
                                      </div>
                                    )}
                                </li>
                                <li>
                                  {shopURL?.includes("alixdrycleaning") && (  
                                      <div className="bar-code-grid">      
                                          <img src={alixandroid} alt=''/>
                                      </div>
                                    )}
                                    <a target="_blank" href={playStore}>
                                        <img src={ihi17} alt="" />
                                    </a>
                                    {shopURL?.includes("starcraftdrycleaners") && (  
                                      <div>
                                      <img src={starcraftandroid} alt="" className="qr11"/>                                  
                                      </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="flapp">
                            <ul className="flapplinks float-right">
                                {settingsData?.WebsiteSettings?.social_link1 != '' && (
                                  <li className="p-3" style={{cursor: 'pointer'}}>
                                    <a href={`${settingsData?.WebsiteSettings?.social_link1}`} target="_blank"><i className="fab fa-facebook"></i></a>
                                  </li>
                                )}
                                {settingsData?.WebsiteSettings?.contact_t3 != '' && (
                                  <li className="p-3" style={{cursor: 'pointer'}}>
                                    <a href={`tel:${settingsData?.WebsiteSettings?.contact_t3}`} target="_blank"><i className="fas fa-phone"></i></a>
                                  </li>
                                )}
                                {settingsData?.WebsiteSettings?.contact_t6 != '' && (
                                  <li className="p-3" style={{cursor: 'pointer'}}>
                                    <a href={`mailto:${settingsData?.WebsiteSettings?.contact_t6}`} target="_blank"><i className="fas fa-envelope"></i></a>
                                  </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-md-9">
                        <div className="flsubsc">
                            <label>Subscribe to our newsletter:</label>
                            <div className="flsubsc-inp-group">
                                <input type="text" />
                                <button className="btn">Subscribe</button>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="flcredits">
                            {/* <img src={ihi14} alt=""/>                             */}
                            <p>© {year} {Object.keys(settings).length > 0 && settings?.WebsiteSettings?.site_title_en}.All rights reserved.| Powered by &nbsp;
                <a href="http://www.bestatservices.com" target="blank">
                  www.bestatservices.com
                </a></p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="flpaymethods">
                            <p>Accepted payment methods:</p>
                            <img src={ihi15} alt=""/> 
                        </div>                        
                    </div>
                </div>
                
        </div>
      </footer>
    </div>
  );
}

export default FooterIhateIroning;
