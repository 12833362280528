import React, { useState } from 'react'
import { ihi16, ihi17,alixappscreen, alixappscreen1, alixappscreen2, alixios, alixandroid } from '../../assets/img';
import { useSelector } from 'react-redux';

const SectionThree = () => {
    const settingsData: any = useSelector<any>((state) => state?.settings);
    const appStorel =
    Object.keys(settingsData).length > 0 &&
    settingsData.WebmasterSettings.app_store_link;
  const playStore =
    Object.keys(settingsData).length > 0 &&
    settingsData.WebmasterSettings.play_store_link;
  return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-12">                    
                    <div className="sec-sitehigh-points">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="sec-high-p-s">
                                    <h2>Get Your Dry Cleaning & Alteration Done With Just One Tap</h2>
                                    <ul className='sec-high-p-list'>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>Seamless Order procedure
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>Card Payment
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>Free Pick up and Delivery
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>24/7 Order Updates
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check"></i>Customer Support
                                        </li>
                                    </ul>
                                    
                                    <div className="app-ico-list">
                                        <div className="app-ico-card">                                            
                                            <div className="bar-code-grid">                                                
                                                <img src={alixios} alt=''/>
                                            </div>
                                            <a target="_blank" href={appStorel}>
                                                <img src={ihi16} alt="" />
                                            </a>
                                        </div>
                                        <div className="app-ico-card">                                            
                                            <div className="bar-code-grid">      
                                                <img src={alixandroid} alt=''/>
                                            </div>
                                            <a target="_blank" href={playStore}>
                                                <img src={ihi17} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="sec-high-p-e">
                                    <img src={alixappscreen1} alt=''/>
                                    <img src={alixappscreen2} alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>                            
                </div>
            </div>
        </div>  
    </>
  )
}

export default SectionThree
