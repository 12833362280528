import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  alphabet,
  email,
  phoneNumber,
  alphaNumericPattern,
} from "../core/regex";
import { postContactForm } from "../../redux/Actions";
import { useSelector } from "react-redux";

const contactUsFormSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(50, "Enter cannot exceed 50 character")
    .matches(alphabet, "Name should be only alphabet")
    .trim(),
  email: yup
    .string()
    .required("Email address is required")
    .matches(email, "Enter a valid email address")
    .max(96, "Enter cannot exceed 96 character")
    .trim(),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(phoneNumber, "Enter a valid mobile number")
    .trim(),
  message: yup
    .string()
    .required("Message is required")
    .matches(
      alphaNumericPattern,
      "Message cannot not contain any special character"
    )
    .max(250, "Message cannot exceed 250 character")
    .trim(),
  contact: yup.number().default(1),
});

function ContactAlix() {
const settingsData: any = useSelector<any>((state) => state?.settings);

  return (
    <>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Contact Us</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>Contact Us</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="my-4">
              <h3 className="text-primary pb-2">
                Contact <span className="text-secondary">Us</span>
              </h3>
              <div className="col-md-12">
                <div className="contgrid">
                    <div className="mb-3 d-flex">
                        <span className="me-2">
                        <i className="fa fa-map-marker"></i>
                        </span>
                        <p>
                        {settingsData?.WebsiteSettings?.contact_t1_en}
                        </p>
                    </div>
                    <div className="mb-3 d-flex">
                        <span className="me-2">
                        <i className="fa fa-phone"></i>
                        </span>
                        <a href={`tel:${settingsData?.WebsiteSettings?.contact_t3}`} target="_blank">
                        {settingsData?.WebsiteSettings?.contact_t3}
                        </a>
                    </div>
                    <div className="d-flex">
                        <span className="me-2">
                        <i className="fa fa-envelope-open"></i>
                        </span>
                        <a href={`mailto:${settingsData?.WebsiteSettings?.contact_t6}`} target="_blank">
                        {settingsData?.WebsiteSettings?.contact_t6}
                        </a>
                    </div>
                </div>
              </div>
              <h3 className="text-primary pb-2">
                Open <span className="text-secondary">Hours</span>
              </h3>
              <div className="col-md-12">
                <div className="contgrid">
                    <div className="d-flex">
                        <span className="me-2">
                        <i className="fa fa-clock"></i>
                        </span>
                        <p>
                        <p>Monday - Saturday 8AM to 6PM.</p>
                        </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="my-4">
              <h3 className="text-primary pb-2">
                Google <span className="text-secondary">Map</span>
              </h3>
              <div className="">
                <iframe src={settingsData?.WebsiteSettings?.contact_t1_en && `https://www.google.com/maps?q=${settingsData?.WebsiteSettings?.contact_t1_en}&output=embed`} width="100%" height="400" loading="lazy"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactAlix;
