import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {  postContactForm } from "../../redux/Actions";
import { alphabet, email, phoneNumber, alphaNumericPattern } from "../../components/core/regex";


const contactUsFormSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(50, "Enter cannot exceed 50 character")
    .matches(alphabet, "Name should be only alphabet")
    .trim(),
  email: yup
    .string()
    .required("Email address is required")
    .matches(email, "Enter a valid email address")
    .max(96, "Enter cannot exceed 96 character")
    .trim(),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(phoneNumber, "Enter a valid mobile number")
    .trim(),
  message: yup
    .string()
    .required("Message is required")
    .matches(
      alphaNumericPattern,
      "Message cannot not contain any special character"
    )
    .max(250, "Message cannot exceed 250 character")
    .trim(),
});

function ContactUs() {
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const dispatch = useDispatch<any>();
  const settingsData: any = useSelector<any>((state) => state?.settings);

  const {
    handleSubmit: contactFormSubmit,
    control: contactFormControl,
    reset: contactFormReset,
    formState: { errors: contactFormErrors },
  } = useForm({
    resolver: yupResolver(contactUsFormSchema),
    defaultValues: {
      email: "",
      message: "",
      name: "",
      mobile: "",
    },
  });

  const submitContactForm = async (data: any) => {
    const response: any = await postContactForm(data);
    if (response.status) {
      // setContactUs("contactus-grid");
      contactFormReset();
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } else {
      setMessage(response.message);
    }
  };

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Business Inquiries</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>Business Inquiries</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="mt-5 mb-4">
              <h2>Business Inquiries</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <form onSubmit={contactFormSubmit(submitContactForm)}>
            <div className="col-md-7">
              <div className="form-contact">
                <div className="mb-3">
                  <label className="form-label">Name *</label>
                  <Controller
                    name="name"
                    control={contactFormControl}
                    render={({ field }) => (
                      <input
                        placeholder="Enter name"
                        {...field}
                        className={`form-control ${
                          contactFormErrors?.name ? "invalid" : ""
                        }`}
                      />
                    )}
                  />
                  {contactFormErrors.name ? (
                    <small className="text-danger">
                      {contactFormErrors.name?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label className="form-label">Email *</label>
                  <Controller
                    name="email"
                    control={contactFormControl}
                    render={({ field }) => (
                      <input
                        placeholder="Enter email"
                        {...field}
                        className={`form-control ${
                          contactFormErrors?.email ? "invalid" : ""
                        }`}
                      />
                    )}
                  />
                  {contactFormErrors.email ? (
                    <small className="text-danger">
                      {contactFormErrors.email?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label className="form-label">Mobile Number *</label>
                  <Controller
                    name="mobile"
                    control={contactFormControl}
                    render={({ field }) => (
                      <input
                        type="text"
                        placeholder="Enter mobile number"
                        {...field}
                        className={`form-control ${
                          contactFormErrors?.mobile ? "invalid" : ""
                        }`}
                      />
                    )}
                  />
                  {contactFormErrors.mobile ? (
                    <small className="text-danger">
                      {contactFormErrors.mobile?.message}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label className="form-label">Message *</label>
                  <Controller
                    name="message"
                    control={contactFormControl}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        placeholder="Enter Message"
                        className={`form-control ${
                          contactFormErrors?.message
                            ? "invalid text-danger"
                            : ""
                        }`}
                      ></textarea>
                    )}
                  />
                  {contactFormErrors.message ? (
                    <small className="text-danger">
                      {contactFormErrors.message?.message}
                    </small>
                  ) : null}
                </div>
                {message != "" ? (
                  <div className="mb-3">
                    <small className="text-danger">{message}</small>
                  </div>
                ) : null}
                <div className="mb-3">
                  <button type="submit" className="btn secondary-btn w-100">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="col-md-5">
            <div className="contgrid">
              <h2 className="mb-3">GET IN TOUCH</h2>
              <div className="mb-3 d-flex">
                <span className="me-2">
                  <i className="fa fa-phone"></i>
                </span>
                <a href={`tel:${settingsData?.WebsiteSettings?.contact_t3}`}>
                  {settingsData?.WebsiteSettings?.contact_t3}
                </a>
              </div>
              <div className="mb-3 d-flex">
                <span className="me-2">
                  <i className="fa fa-envelope-open"></i>
                </span>
                <a href={`mailto:${settingsData?.WebsiteSettings?.contact_t6}`}>
                  {settingsData?.WebsiteSettings?.contact_t6}
                </a>
              </div>
              <div className="mb-3 d-flex">
                <span className="me-2">
                  <i className="fa fa-map-marker"></i>
                </span>
                <p>
                  SR Dry Cleaning and Laundries Ltd T/A White Rose, Europa House,
                  Hanger Lane, London, W5 1DP 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
