import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";

import {
  appstore,
  googleplay,
  heroimg,
  banner1,
  banner2,
} from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { fetchPostalCodes } from "../../redux/Actions";
import { checkShopListFlow, setValue } from "../../utility";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchSaveAddress,
} from "../../redux/Actions/checkoutPageActions";
import { DebounceInput } from "react-debounce-input";
import * as yup from "yup";
import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import SectionOfferSingle from "../offerSingle";
import axios from "axios";

const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Postal code is required")
    .matches(UKPostalCode, "Enter a valid Postal code")
    .trim()
    .max(8, "Enter a valid Postal code"),
});

const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

function SectionOne () {
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [coveredArea, setCoveredArea] = useState(false);
  const [areaData, setAreaData] = useState([]);
  const [selectedSuggestedAddress, setSelectedSuggestedAddress] =
    useState<any>(null);
  const [listOfSuggestedAddress, setListOfSuggestedAddress] = useState([]);
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  let shopURL = window.location.href;
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  useEffect(() => {}, [homedata]);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
    }
  }, [state]);

  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });
  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  useEffect(() => {
    if (checkWithNewPin == false) {
      setPinCodeFormValue("pinCode", "");
      setSelectedAddress(null);
      resetAddressForm();
    } else {
    }
  }, [checkWithNewPin]);

  useEffect(() => {
    let existingAddressSuggestion = JSON.parse(
      localStorage.getItem("addressSuggestions") || "[]"
    );
    setListOfSuggestedAddress(existingAddressSuggestion);
  }, []);

  const handleChange = (e: any) => {
    setCoveredArea(false);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    setPinCodeFormValue("pinCode", updateValue);
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    setPinCodeFormValue("pinCode", searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase());
  };

  const submitAddressFromSuggestion = async (selected: any) => {
    setSelectedSuggestedAddress(selected);
    localStorage.setItem("postalCode", selected.postalCode);
    localStorage.setItem(
      "selectedAddressBeforeLogin",
      JSON.stringify(selected)
    );
    if (localStorage.getItem("token")) {
      // handle api
      const contact_name = JSON.parse(localStorage.getItem("userDetails")!).data
        ?.user_info.name;
      const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
        .data?.user_info.mobile;
      const address = selected?.value?.split(",");
      const payloadData = {
        address_type: "pickup",
        address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
          selected.postalCode
        )}"}`,
      };
      const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
      if (
        uniqueAddress.status ||
        uniqueAddress.message == "Address already exists"
      ) {
        if (checkShopListFlow()) {
            navigate("/shopList");
          } else {
            navigate("/productLists");
          }
      } else {
        addressFormSetError("address", {
          type: "server",
          message: "Unable to set address try again later",
        });
      }
    } else {
      if (checkShopListFlow()) {
            navigate("/shopList");
          } else {
            navigate("/productLists");
          }
    }
  };

  const submitAddressChange = async () => {
    let postalCode: any = localStorage.getItem("postalCode");
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      let addressSuggestions: any = [{ ...selectedAddress }];
      addressSuggestions[0].postalCode = postalCode;
      if (localStorage.getItem("addressSuggestions")) {
        let existingAddressSuggestion = JSON.parse(
          localStorage.getItem("addressSuggestions") || "[]"
        );
        addressSuggestions = addressSuggestions.concat(
          existingAddressSuggestion
        );
      }
      localStorage.setItem(
        "addressSuggestions",
        JSON.stringify(addressSuggestions)
      );
      setListOfSuggestedAddress(addressSuggestions);
      if (localStorage.getItem("token")) {
        // handle api
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (
          uniqueAddress.status ||
          uniqueAddress.message == "Address already exists"
        ) {
          if (checkShopListFlow()) {
            navigate("/shopList");
          } else {
            navigate("/productLists");
          }
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        if (checkShopListFlow()) {
            navigate("/shopList");
          } else {
            navigate("/productLists");
          }
      }
    }
  };

  const fetchCoveredAreaCodes = async () => {
    const base_url = process.env.REACT_APP_BACKEND_URL;
    try {
      const response = await axios({
        method: "get",
        url: `${base_url}/api/areas_covered_postcodes`,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Methods": "GET, POST",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (response.data.Response.response_code == "1") {
        setAreaData(response.data.data);
      } else {
        return false;
      }
    } catch (error) {}
  };

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    if (shop) {
      setCoveredArea(false);
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
        setCoveredArea(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
        await fetchCoveredAreaCodes();
        setCoveredArea(true);
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
      await fetchCoveredAreaCodes();
        setCoveredArea(true);
    }
  };

  return (
    <>
      <div
        className="home_single_search">
        <div className="container">
          
          {shopURL?.includes("claphamdrycleaners") ? (
            <>
              <div className="row h-100 align-items-center justify-content-between">
              <div className="col-auto">
                  <div className="whgrid">
                  <h3>Working Hours</h3>
                  <ul>
                    <li>
                      <span className="whday">Monday</span>
                      <span>8.30 AM - 6.30PM</span>
                    </li>
                    <li>
                      <span className="whday">Tuesday</span>
                      <span>8.30 AM - 6.30PM</span>
                    </li>
                    <li>
                      <span className="whday">Wednesday</span>
                      <span>8.30 AM - 6.30PM</span>
                    </li>
                    <li>
                      <span className="whday">Thursday</span>
                      <span>8.30 AM - 6.30PM</span>
                    </li>
                    <li>
                      <span className="whday">Friday</span>
                      <span>8.30 AM - 6.30PM</span>
                    </li>
                    <li>
                      <span className="whday">Saturday</span>
                      <span>9.00 AM - 4.00PM</span>
                    </li>
                    <li>
                      <span className="whday">Sunday</span>
                      <span>10.00 AM - 3.00PM</span>
                    </li>
                  </ul>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="pargrid">
                    <h4>Parcel Pickup and Drop-off Services</h4>
                    <div className="pargridflex">
                        <div className="parserv">
                          <h5>DPP</h5>
                          <span>10-to 2</span>
                          <p>Our services are available Monday to Sunday from 10am to 2pm. On Saturdays and Sundays, our services are available throughout our opening hours, so you can drop off or collect your parcels at a time that suits you.</p>
                        </div>
                        <div className="parserv">
                          <h5>Inpost</h5>
                          <span>10-to 2</span>
                          <p>Our services are available Monday to Sunday from 10am to 2pm. On Saturdays and Sundays, our services are available throughout our opening hours, so you can drop off or collect your parcels at a time that suits you.</p>
                        </div>
                    </div>
                  </div>
              </div>
              </div>
            </>
          ) : (
              <>
              <div className="row justify-content-end h-100" id="postForm">
              <div className="col-md-12 col-lg-4">
              <div className="banner-search-wrap">
                {shopURL?.includes("alixdrycleaning") ? (
                  <h1>Dry Cleaning, Alteration & Repairs Experts</h1>
                ) : (
                  <h1>Dry Cleaning & Laundry Experts</h1>
                )}
                <h5 className="homedesc">
                    with Collection and Delivery in 48 hours
                </h5>

                <div className="postcodeform-container">
                  <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                    <div
                      className={`${checkWithNewPin == false ? "d-none" : ""}`}
                    >
                      <small className="text-danger postvalidation">
                        {pinCodeFormError.pinCode?.message}
                      </small>
                      <div className="postcodeform">
                        <Controller
                          name="pinCode"
                          control={pinCodeFormControl}
                          render={({ field }) => (
                            <input
                              type="text"
                              id="postcode-input"
                              {...field}
                              placeholder="Enter Postcode"
                              className={`ui-autocomplete-input ${
                                pinCodeFormError.pinCode ? "invalid" : ""
                              }`}
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          )}
                        />
                        <button
                          className="btn hover-btn"
                          id="book_now_btn"
                          type="submit"
                          style={{
                            background: `#${homedata?.theme_3?.home?.section_1?.btn_group?.btn1?.bg_color}`,
                            color: `#${homedata?.theme_3?.home?.section_1?.btn_group?.btn1?.color}`,
                          }}
                        >
                          {
                            homedata?.theme_3?.home?.section_1?.btn_group?.btn1
                              ?.text
                          }
                        </button>
                        <div className="dropdown">
                          {postalCodeList
                            .filter((item: any) => {
                              const searchTerm = postalCodeValue.toLowerCase();
                              const mainValue = item.postcode.toLowerCase();

                              return (
                                searchTerm &&
                                mainValue.startsWith(searchTerm) &&
                                mainValue !== searchTerm
                              );
                            })
                            .slice(0, 10)
                            .map((item: any) => (
                              <div
                                onClick={() => onSearch(item.postcode)}
                                className="dropdown-row"
                                key={item.postcode}
                              >
                                <i className="fas fa-map-marker-alt"></i>
                                {item.postcode}
                              </div>
                            ))}
                        </div>
                        {coveredArea && (
                              <div className="dropdown">
                                <div className="dropdown-heading">
                                  Postcode areas we currently covered
                                </div>
                                {areaData.map((item: any) => (
                                  <div
                                    onClick={() => {
                                      onSearch(item);
                                      setCoveredArea(false);
                                    }}
                                    className="dropdown-row"
                                    key={item}
                                  >
                                    <i className="fas fa-map-marker-alt"></i>
                                    {item}
                                  </div>
                                ))}
                              </div>
                            )}
                      </div>
                    </div>
                  </form>
                  <form>
                    <div
                      className={`adrsselectform ${
                        checkWithNewPin == true ? "d-none" : ""
                      }`}
                    >
                      <small className="text-danger postvalidation">
                        {addressFormError.address?.message}
                      </small>
                      <div className="adrsselectdd">
                        <Controller
                          name="address"
                          control={addressFormControl}
                          render={({ field }) => (
                            <Select
                              placeholder="Select your address"
                              {...field}
                              value={selectedAddress}
                              className={`${
                                addressFormError.address ? "invalid" : ""
                              }`}
                              onChange={handleAddressChange}
                              onFocus={() => {
                                if (
                                  getValuesAddressForm("address").value == ""
                                ) {
                                  addressFormSetError("address", {
                                    type: "server",
                                    message: "Address is required to proceed",
                                  });
                                } else {
                                  clearErrors();
                                }
                              }}
                              options={areaOptions}
                            />
                          )}
                        />

                        <input
                          type="button"
                          value="Continue"
                          className="btn hover-btn"
                          onClick={submitAddressChange}
                        />
                      </div>
                      <div className="adrsselect-or">OR</div>
                      <div className="adrsselect-link">
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            setCheckWithNewPin(true);
                          }}
                        >
                          Check with another Postcode
                        </a>
                      </div>
                    </div>
                  </form>
                  {listOfSuggestedAddress.length > 0 && (
                    <div className={`adrsselectform`}>
                      <small className="d-block text-center my-2">
                        Or, Select recent address
                      </small>
                      <div className="adrsselectdd">
                        <Swiper
                          spaceBetween={15}
                          navigation={true}
                          modules={[Navigation]}
                          pagination={{
                            el: ".swiper-pagination",
                            clickable: true,
                          }}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 1,
                            },
                            1024: {
                              slidesPerView: 1,
                            },
                          }}
                          className="slider-adrs"
                        >
                          {listOfSuggestedAddress.map(
                            (address: any, index: any) => {
                              return (
                                <>
                                  <SwiperSlide key={index}>
                                    <div className="adrsgrid">
                                      {/* <i className="fas fa-arrow-alt-circle-right me-3"></i> */}
                                      <div
                                        className="adrsgriddv"
                                        key={index}
                                        onClick={() =>
                                          submitAddressFromSuggestion(address)
                                        }
                                      >
                                        {/* <i className="fas fa-map-marker-alt me-2"></i> */}
                                        <div className="adrs-label">
                                          {address.label}
                                          <span className="adrs-pcode">
                                            {address.postalCode}
                                          </span>
                                        </div>
                                      </div>
                                      {/* <i className="fas fa-map-marker-alt ms-auto"></i> */}
                                    </div>
                                  </SwiperSlide>
                                </>
                              );
                            }
                          )}
                        </Swiper>
                        {/* <Select
                        placeholder="Select recent address"
                        value={selectedSuggestedAddress}
                        onChange={submitAddressFromSuggestion}
                        options={listOfSuggestedAddress}
                      /> */}
                      </div>
                    </div>
                  )}
                </div>
                {/* {homedata?.theme_3?.home?.section_1?.mobile_link_section
                  ?.is_enable && (
                  <div className="banner-app mt-4">
                    <h4>
                      {
                        homedata?.theme_3?.home?.section_1?.mobile_link_section
                          ?.mob_title
                      }
                    </h4>
                    <ul className="banner-app-links">
                      <li>
                        <a
                          target="_blank"
                          href={
                            homedata?.theme_3?.home?.section_1
                              ?.mobile_link_section?.ios_link
                          }
                        >
                          <img src={appstore} alt="" />
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href={
                            homedata?.theme_3?.home?.section_1
                              ?.mobile_link_section?.android_link
                          }
                        >
                          <img src={googleplay} alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                )} */}
                <SectionOfferSingle/>
              </div>
            </div>
            </div>
              </>
           ) } 
            
          
        </div>
      </div>
    </>
  );
}

export default SectionOne;
