import React, { useState } from "react";
import Home from "../../pages/Home";
import Pricing from "./../../pages/pricing";
import { Route, Routes, Link } from "react-router-dom";
import AreaCovered from "../../pages/areaCovered";
import Footer from "../footer/footerThree";
import FAQ from "../../pages/faq";
import MyProfile from "./../profile/myProfile/myProfile";
import MyAddress from "../profile/myAddress/myAddress";
import RushMoor from "../rushmoor";
import MyOrders from "../profile/myOrders";
import OrderView from "../profile/orderView";
import AboutUs from "../aboutUs";
import Login from "../login";
import ProductLists from "../productLists";
import CheckOut from "../checkout";
import ForgotPassword from "../forgotPassword";
import AreaNotCovered from "../areaNotCovered";
import GuestLogin from "../guestLogin";
import ResetPassword from "../resetPassword";
import ThankYou from "../thankYou";
import AllNotifications from "../notification/allNotifications";
import EditProfile from "../profile/myProfile/editProfile";
import NewAddress from "../profile/myAddress/newAddress";
import EditAddress from "../profile/myAddress/editAddress";
import TermsAndConditions from "../termsAndConditions";
import PrivacyPolicy from "../privacyPolicy";
import CookiePolicy from "../cookiePolicy";
import ReferFriend from "../profile/referFriend";
import ShopHome from "../shopHome";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import PrivateRoute from "./privateRoutes";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import Pusher from "pusher-js";
import { toast } from "react-toastify";
import ScrollToTop from "../scrolltoTop/index";
import SignUp from "../signUp";
import Edit from "../edit";
import HeaderThree from "../header/headerThree";
import { getNotification, getSettingsData } from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderOne from "../header/headerOne";
import FooterThree from "../footer/footerThree";
import FooterOne from "../footer/footerOne";
import SLoader from "../skeleton";
import Reviews from "../reviews";
import HeaderTwo from "../header/headerTwo";
import FooterTwo from "../footer/footerTwo";
import HeaderUplands from "../header/headerUplands";
import FooterUplands from "../footer/footerUplands";
import Laundry from "../upLands/laundry";
import Ironing from "../upLands/ironing";
import DryCleaning from "../upLands/drycleaning";
import Alterations from "../upLands/alterations";
import PriceList from "../upLands/pricelist";
import HouseCleaning from "../upLands/housecleaning";
import CarpetCleaning from "../upLands/carpetcleaning";
import Contact from "../upLands/contact";
import HeaderFour from "../header/headerFour";
import TTLaundry from "../ttSlider/laundry";
import TTPress from "../ttSlider/press";
import TTBedLinenCleaning from "../ttSlider/bedLinenCleaning";
import TTCollectionDelivery from "../ttSlider/collectionDelivery";
import TTCurtainsCleaning from "../ttSlider/curtainsCleaning";
import TTDryCleaning from "../ttSlider/dryCleaning";
import TTDuvetCleaning from "../ttSlider/duvetCleaning";
import TTEvening from "../ttSlider/evening";
import TTHouseholdcleaning from "../ttSlider/householdCleaning";
import TTLaoundreet from "../ttSlider/laoundreet";
import TTRepairalterations from "../ttSlider/repairAlterations";
import TTSudeleathercleaning from "../ttSlider/sudeLeatherCleaning";
import TTWeddingdresscleaning from "../ttSlider/weddingDressCleaning";
import FooterFour from "../footer/footerFour";
import HeaderFive from "../header/headerFive";
import FooterFive from "../footer/footerFive";
import ContactUs from "../../pages/contactUs";
import HeaderRogers from "../header/headerRogers";
import FooterRogers from "../footer/footerRogers";
import HeaderWheatley from "../header/headerWheatley";
import BookingDomestic from "../wheatleyLaundry/bookingDomestic";
import FooterWheatley from "../footer/footerWheatley";
import BookingBusiness from "../wheatleyLaundry/bookingBusiness";
import WheatleyCart from "../wheatleyLaundry/cart";
import CheckoutWheatley from "../wheatleyLaundry/wheatleycheckout";
import ContactWheatley from "../wheatleyLaundry/contactus";
import UnderMaintenance from "../underMaintenance";
import EditShopTheme from "../shopHome/edit/index";
import ShopList from "../shopList";
import FooterOneShop from "../shopHome/theme-one/footerOne";
import FooterTwoShop from "../shopHome/theme-two/footerTwo";
import FooterThreeShop from "../shopHome/theme-three/footerThree";
import FooterFourShop from "../shopHome/theme-four/footerFour";
import FooterFiveShop from "../shopHome/theme-five/footerFive";
import HeaderOneShop from "../shopHome/theme-one/headerOne";
import HeaderTwoShop from "../shopHome/theme-two/headerTwo";
import HeaderThreeShop from "../shopHome/theme-three/headerThree";
import HeaderFourShop from "../shopHome/theme-four/headerFour";
import HeaderFiveShop from "../shopHome/theme-five/headerFive";
import { fetchShopHomeData } from "../../redux/Actions/shopHomeAction";
import { lazy, Suspense } from 'react';
import Loader from "../loader";
import HeaderLaundryHeap from "../header/headerLaundryHeap";
import HeaderBlack from "../header/headerBlack";
import FooterBlack from "../footer/footerBlack";
import HeaderIhateIroning from "../header/headerIhateIroning";
import FooterIhateIroning from "../footer/footerIhateIroning";
import HeaderShopEleven from "../shopHome/theme-eleven/headershopeleven";
import HeaderShopNine from "../shopHome/theme-nine/header";
import FooterShopEleven from "../shopHome/theme-eleven/footershopeleven";
import HeaderTen from "../shopHome/theme-ten/header";
import FooterTen from "../shopHome/theme-ten/footer";
import Hotjar from '@hotjar/browser';
import HeaderTweleve from "../header/headerTweleve";
import FooterTweleve from "../footer/footerTweleve";
import HeaderShopTweleve from "../shopHome/theme-tweleve/headertweleve";
import FooterShopTweleve from "../shopHome/theme-tweleve/footertweleve";
import ShoeCleaning from "../theme-tweleve/shoeCleaning";
import HeloLocation from "../theme-tweleve/location";
import HeloAllLocation from "../theme-tweleve/allLocation";
import HeaderThirteen from "../header/headerThirteen";
import FooterThirteen from "../footer/footerThirteen";
import ShoeRepair from "../theme-tweleve/shoeRepair";
import ProductListsCobbler from "../productCobbler";
import Page404 from "../theme-tweleve/404";
import ShopReviews from "../shopReviews";
import Commercial from "../laundryheap/commercial";
import Sec9 from "../laundryheap/sec9";
import ContactAlix from "../ihateironing/contact";


const HomeComponent:any = lazy(() => import('../../pages/Home'));
const PricingComponent:any = lazy(() => import('./../../pages/pricing'));
const AreaCoveredComponent:any = lazy(() => import('../../pages/areaCovered'));
const AllNotificationsComponent:any = lazy(() => import('../notification/allNotifications'));
const FAQComponent:any = lazy(() => import('../../pages/faq'));
const UnderMaintenanceComponent:any = lazy(() => import('../underMaintenance'));
const MyProfileComponent:any = lazy(() => import('./../profile/myProfile/myProfile'));
const EditProfileComponent:any = lazy(() => import('../profile/myProfile/editProfile'));
const MyAddressComponent:any = lazy(() => import('../profile/myAddress/myAddress'));
const NewAddressComponent:any = lazy(() => import('../profile/myAddress/newAddress'));
const EditAddressComponent:any = lazy(() => import('../profile/myAddress/editAddress'));
const RushMoorComponent:any = lazy(() => import('../rushmoor'));
const MyOrdersComponent:any = lazy(() => import('../profile/myOrders'));
const OrderViewComponent:any = lazy(() => import('../profile/orderView'));
const AboutUsComponent:any = lazy(() => import('../aboutUs'));
const ReviewsComponent:any = lazy(() => import('../reviews'));
const LoginComponent:any = lazy(() => import('../login'));
const SignUpComponent:any = lazy(() => import('../signUp'));
const ProductListsComponent:any = lazy(() => import('../productLists'));
const CheckOutComponent:any = lazy(() => import('../checkout'));
const ThankYouComponent:any = lazy(() => import('../thankYou'));
const ReferFriendComponent:any = lazy(() => import('../profile/referFriend'));
const ForgotPasswordComponent:any = lazy(() => import('../forgotPassword'));
const AreaNotCoveredComponent:any = lazy(() => import('../areaNotCovered'));
const GuestLoginComponent:any = lazy(() => import('../guestLogin'));
const ResetPasswordComponent:any = lazy(() => import('../resetPassword'));
const TermsAndConditionsComponent:any = lazy(() => import('../termsAndConditions'));
const PrivacyPolicyComponent:any = lazy(() => import('../privacyPolicy'));
const CookiePolicyComponent:any = lazy(() => import('../cookiePolicy'));
const ShopHomeComponent:any = lazy(() => import('../shopHome'));
const EditComponent:any = lazy(() => import('../edit'));
const EditShopThemeComponent:any = lazy(() => import('../shopHome/edit/index'));
const LaundryComponent:any = lazy(() => import('../upLands/laundry'));
const IroningComponent:any = lazy(() => import('../upLands/ironing'));
const DryCleaningComponent:any = lazy(() => import('../upLands/drycleaning'));
const AlterationsComponent:any = lazy(() => import('../upLands/alterations'));
const PriceListComponent:any = lazy(() => import('../upLands/pricelist'));
const HouseCleaningComponent:any = lazy(() => import('../upLands/housecleaning'));
const CarpetCleaningComponent:any = lazy(() => import('../upLands/carpetcleaning'));
const ContactComponent:any = lazy(() => import('../upLands/contact'));
const ContactUsComponent:any = lazy(() => import('../../pages/contactUs'));
const TTLaundryComponent:any = lazy(() => import('../ttSlider/laundry'));
const TTPressComponent:any = lazy(() => import('../ttSlider/press'));
const TTBedLinenCleaningComponent:any = lazy(() => import('../ttSlider/bedLinenCleaning'));
const ShopListComponent:any = lazy(() => import('../shopList'));
const TTCollectionDeliveryComponent:any = lazy(() => import('../ttSlider/collectionDelivery'));
const TTCurtainsCleaningComponent:any = lazy(() => import('../ttSlider/curtainsCleaning'));
const TTDryCleaningComponent:any = lazy(() => import('../ttSlider/dryCleaning'));
const TTDuvetCleaningComponent:any = lazy(() => import('../ttSlider/duvetCleaning'));
const TTEveningComponent:any = lazy(() => import('../ttSlider/evening'));
const TTHouseholdcleaningComponent:any = lazy(() => import('../ttSlider/householdCleaning'));
const TTLaoundreetComponent:any = lazy(() => import('../ttSlider/laoundreet'));
const TTRepairalterationsComponent:any = lazy(() => import('../ttSlider/repairAlterations'));
const TTSudeleathercleaningComponent:any = lazy(() => import('../ttSlider/sudeLeatherCleaning'));
const TTWeddingdresscleaningComponent:any = lazy(() => import('../ttSlider/weddingDressCleaning'));
const BookingDomesticComponent:any = lazy(() => import('../wheatleyLaundry/bookingDomestic'));
const BookingBusinessComponent:any = lazy(() => import('../wheatleyLaundry/bookingBusiness'));
const WheatleyCartComponent:any = lazy(() => import('../wheatleyLaundry/cart'));
const CheckoutWheatleyComponent:any = lazy(() => import('../wheatleyLaundry/wheatleycheckout'));
const ContactWheatleyComponent:any = lazy(() => import('../wheatleyLaundry/contactus'));


function App() {
  const dispatch = useDispatch<any>();
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    () => localStorage.getItem("logged_user") !== null
  );
  const navigate = useNavigate();
  const location = useLocation();
  let url = location.pathname;
  let splitURL: any = url.toString().split("/"); 

  useEffect(() => {
    localStorage.setItem("logged_user", JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(getSettingsData());
    if (settingsData?.WebsiteSettings) {
      document.getElementById('favicon')?.setAttribute('href', `${settingsData?.WebsiteSettings?.logo_path + settingsData?.WebsiteSettings?.style_fav}`);
    }
  }, []);

  useEffect(() => {
    let host = window.location.hostname;
    let origin = "https://demo2.bestatlaundry.com";
    let companyName = "";

    if (host !== "localhost") {
      origin = window.location.origin;
      let client = origin.split("/");
      client = client[client.length - 1].split(".");
      companyName = client[0];
      if (settingsData?.WebsiteSettings?.site_status == 0) {
        navigate("/maintenance");
      }
    }

    if (
      settingsData?.WebsiteSettings?.site_title_en.includes("T&T") ||
      settingsData?.WebsiteSettings?.site_title_en.includes("TT")
    ) {
      import("../../assets/css/tt.css");
    }
    if (
      host.includes("sasian") ||
      settingsData?.WebsiteSettings?.site_title_en.includes("Sasian")
    ) {
      import("../../assets/css/sasian.css");
    }
    if (
      host.includes("Whiterose") ||
      settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose")
    ) {
      import("../../assets/css/whiterose.css");
    }
    if (
      host.includes("VIP") ||
      settingsData?.WebsiteSettings?.site_title_en.includes("VIP")
    ) {
      import("../../assets/css/vip.css");
    }
    if (
      host.includes("rogers") ||
      settingsData?.WebsiteSettings?.site_title_en.includes("Rogers")
    ) {
      import("../../assets/css/sasian.css");
    }
    if (
      host.includes("Sheer") ||
      settingsData?.WebsiteSettings?.site_title_en.includes("Sheer")
    ) {
      import("../../assets/css/sheer.css");
    }
    if (settingsData?.template == 1) {
      import("../../assets/css/style1.css");
    } else if (settingsData?.template == 2) {
      import("../../assets/css/style2.css");
    } else if (settingsData?.template == 3) {
      import("../../assets/css/style3.css");
    } else if (settingsData?.template == 4) {
      import("../../assets/css/style4.css");
    } else if (settingsData?.template == 5) {
      import("../../assets/css/style5.css");
    } else if (settingsData?.template == 6) {
      import("../../assets/css/uplands.css");
    } else if (settingsData?.template == 7) {
      import("../../assets/css/rogers.css");
    } else if (settingsData?.template == 8) {
      import("../../assets/css/wheatley.css");
    } else if (settingsData?.template == 9) {
      import("../../assets/css/laundryheap.css");
    } else if (settingsData?.template == 10) {
      import("../../assets/css/black.css");
    } else if (settingsData?.template == 11) {
      import("../../assets/css/ihate.css");
    } else if (settingsData?.template == 12) {
      import("../../assets/css/style12.css");
    } else if (settingsData?.template == 13) {
      import("../../assets/css/style13.css");
    }
    if (
      host.includes("londonslaundry") ||
      settingsData?.WebsiteSettings?.site_title_en.includes("Londons Laundry")
    ) {
      import("../../assets/css/londonslaundry.css");
    }
    if (
      host.includes("madinahdryclean") ||
      settingsData?.WebsiteSettings?.site_title_en.includes("Madinah Dry Cleaners")
    ) {
      import("../../assets/css/madinahdryclean.css");
    }
  }, [settingsData]);

  const checkHeader = () => {
    return (
      splitURL[1] !== "shop" &&
      splitURL[1] !== "edit" &&
      splitURL[1] !== "maintenance" &&
      splitURL[1] !== "edit-shop-home"
    );
  };

  const checkHeaderShop = () => {
    return splitURL.length >= 3 && splitURL[3] !== "productLists" && splitURL[3] !== "product-lists" && splitURL[3] !== "checkOut" && shopHomeData?.home_page_settings?.theme_selected === "" && splitURL[1] !== "orderView";
};

  const checkHeaderShopHome = () => {
    return splitURL[3] === "productLists" || splitURL[3] === "product-lists" || splitURL[3] === "checkOut" && shopHomeData?.home_page_settings?.theme_selected === "" || shopHomeData?.template === "5" && splitURL[1] === "shop";
  };

  useEffect(() => {
    // localStorage.setItem("postalCode", "GU111BH")
    if (splitURL.length > 3) {
      console.log("shomeapp1")
      dispatch(fetchShopHomeData());
    }
  }, []);

  const siteId = 5000136;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  return (
    <>
      <title>{settingsData?.WebsiteSettings?.site_title_en}</title>

      <ScrollToTop>
        <SLoader />
        {checkHeader() && settingsData?.template == 1 && <HeaderOne />}
        {checkHeader() && settingsData?.template == 2 && <HeaderTwo />}
        {checkHeader() && settingsData?.template == 3 && <HeaderThree />}
        {checkHeader() && settingsData?.template == 4 && <HeaderFour />}
        {checkHeader() && settingsData?.template == 5 && <HeaderFive />}

        {checkHeaderShop() && shopHomeData?.template == 1 && <HeaderOneShop />}
        {checkHeaderShop() && shopHomeData?.template == 2 && <HeaderTwoShop />}
        {checkHeaderShop() && shopHomeData?.template == 3 && <HeaderThreeShop />}
        {checkHeaderShop() && shopHomeData?.template == 4 && <HeaderFourShop />}
        {checkHeaderShop() && shopHomeData?.template == 9 && <HeaderShopNine />}
        {checkHeaderShop() && shopHomeData?.template == 10 && <HeaderTen />}
        {checkHeaderShop() && shopHomeData?.template == 11 && <HeaderShopEleven />}
        {checkHeaderShop() && shopHomeData?.template == 12 && <HeaderShopTweleve />}
        {checkHeaderShopHome() && <HeaderFive />}

        {checkHeader() && settingsData?.template == 6 && <HeaderUplands />}
        {checkHeader() && settingsData?.template == 7 && <HeaderRogers />}
        {checkHeader() && settingsData?.template == 8 && <HeaderWheatley />}
        {checkHeader() && settingsData?.template == 9 && <HeaderLaundryHeap />}
        {checkHeader() && settingsData?.template == 10 && <HeaderBlack />}
        {checkHeader() && settingsData?.template == 11 && <HeaderIhateIroning />}
        {checkHeader() && settingsData?.template == 12 && <HeaderTweleve />}
        {checkHeader() && settingsData?.template == 13 && <HeaderThirteen />}
        <Suspense fallback={<div><Loader /></div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/prices" element={<Pricing />} />
          <Route path="/areaCovered" element={<AreaCovered />} />
          <Route path="/notifications" element={<AllNotifications />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/maintenance" element={<UnderMaintenance />} />
          <Route
            path="/myProfile"
            element={
              <PrivateRoute>
                {" "}
                <MyProfile />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/editProfile"
            element={
              <PrivateRoute>
                <EditProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/myAddress"
            element={
              <PrivateRoute>
                <MyAddress />
              </PrivateRoute>
            }
          />

          <Route
            path="/newAddress"
            element={
              <PrivateRoute>
                <NewAddress />
              </PrivateRoute>
            }
          />
          <Route
            path="/editAddress/:address"
            element={
              <PrivateRoute>
                <EditAddress />
              </PrivateRoute>
            }
          />
          <Route path="/dry-cleaners/:location" element={<RushMoor />} />
          <Route
            path="/myOrders"
            element={
              <PrivateRoute>
                <MyOrders />
              </PrivateRoute>
            }
          />
          <Route
            path="/orderView/:orderId"
            element={
              <PrivateRoute>
                <OrderView />
              </PrivateRoute>
            }
          />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/shopreviews" element={<ShopReviews />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/login/:tokenref" element={<Login />} />
          <Route path="/productLists" element={<ProductLists />} />
          <Route path="/product-lists" element={<ProductListsCobbler />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/checkOut" element={<CheckOut />} />

          {/* shop home routes */}
          <Route
            path="/shop/:finalResult/productLists"
            element={<ProductLists />}
          />
          <Route path="/shop/:finalResult/checkOut" element={<CheckOut />} />
          <Route path="/shop/:finalResult/thankYou" element={<ThankYou />} />
          <Route
            path="/shop/:finalResult/orderView/:orderId"
            element={
              <PrivateRoute>
                <OrderView />
              </PrivateRoute>
            }
          />
          <Route
            path="/shop/:finalResult/myOrders"
            element={
              <PrivateRoute>
                <MyOrders />
              </PrivateRoute>
            }
          />
          <Route
            path="/shop/:finalResult/myProfile"
            element={
              <PrivateRoute>
                {" "}
                <MyProfile />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/shop/:finalResult/referFriend"
            element={
              <PrivateRoute>
                {" "}
                <ReferFriend />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/shop/:finalResult/myAddress"
            element={
              <PrivateRoute>
                <MyAddress />
              </PrivateRoute>
            }
          />
          <Route path="/shop/:finalResult/pricing" element={<Pricing />} />
          <Route path="/shop/:finalResult/areaCovered" element={<AreaCovered />} />
          <Route path="/shop/:finalResult/faq" element={<FAQ />} />
          <Route path="/shop/:finalResult/aboutUs" element={<AboutUs />} />
          <Route path="/shop/:finalResult/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/shop/:finalResult/termsAndConditions" element={<TermsAndConditions />} />
          {/* shop home routes */}

          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/areaNotCovered" element={<AreaNotCovered />} />
          <Route path="/guestLogin" element={<GuestLogin />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
          <Route path="/thankYou" element={<ThankYou />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookiePolicy" element={<CookiePolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/referFriend" element={<ReferFriend />} />
          <Route path="/shop/:finalResult" element={<ShopHome />} />
          <Route path="/edit" element={<Edit />} />
          <Route path="/edit-shop-home" element={<EditShopTheme />} />
          <Route path="/laundry" element={<Laundry />} />
          <Route path="/ironing" element={<Ironing />} />
          <Route path="/drycleaning" element={<DryCleaning />} />
          <Route path="/alterations" element={<Alterations />} />
          <Route path="/pricelist" element={<PriceList />} />
          <Route path="/housecleaning" element={<HouseCleaning />} />
          <Route path="/carpetcleaning" element={<CarpetCleaning />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Contactus" element={<ContactUs />} />
          <Route path="/contact-us" element={<ContactAlix />} />
          <Route path="/ttlaundry" element={<TTLaundry />} />
          <Route path="/ttpress" element={<TTPress />} />
          <Route path="/ttbedlinencleaning" element={<TTBedLinenCleaning />} />
          <Route path='/shoplist' element={<ShopList />} />
          <Route path='/commercial-laundry-service' element={<Commercial />} />
          <Route
            path="/ttcollectiondelivery"
            element={<TTCollectionDelivery />}
          />
          <Route path="/ttcurtainscleaning" element={<TTCurtainsCleaning />} />
          <Route path="/ttdrycleaning" element={<TTDryCleaning />} />
          <Route path="/ttduvetcleaning" element={<TTDuvetCleaning />} />
          <Route path="/ttevening" element={<TTEvening />} />
          <Route
            path="/tthouseholdcleaning"
            element={<TTHouseholdcleaning />}
          />
          <Route path="/ttlaoundreet" element={<TTLaoundreet />} />
          <Route
            path="/ttrepairalterations"
            element={<TTRepairalterations />}
          />
          <Route
            path="/ttsudeleathercleaning"
            element={<TTSudeleathercleaning />}
          />
          <Route
            path="/ttweddingdresscleaning"
            element={<TTWeddingdresscleaning />}
          />
          <Route path="/bookingdomestic" element={<BookingDomestic />} />
          <Route path="/bookingbusiness" element={<BookingBusiness />} />
          <Route path="/cart" element={<WheatleyCart />} />
          <Route path="/wheatleycheckout" element={<CheckoutWheatley />} />
          <Route path="/contactwheatley" element={<ContactWheatley />} />
          <Route path="/shoe-cleaning" element={<ShoeCleaning/>}/>
          <Route path="/shoe-repair" element={<ShoeRepair/>}/>
          <Route path="/location/:location" element={<HeloLocation/>}/>
          <Route path="/all-location" element={<HeloAllLocation/>}/>
          
        </Routes>
        </Suspense>
        {checkHeader() && settingsData?.template == 1 && <FooterOne />}
        {checkHeader() && settingsData?.template == 2 && <FooterTwo />}
        {checkHeader() && settingsData?.template == 3 && <FooterThree />}
        {checkHeader() && settingsData?.template == 4 && <FooterFour />}
        {checkHeader() && settingsData?.template == 5 && <FooterFive />}

        {checkHeaderShop() && shopHomeData?.template == 1 && <FooterOneShop />}
        {checkHeaderShop() && shopHomeData?.template == 2 && <FooterTwoShop />}
        {checkHeaderShop() && shopHomeData?.template == 3 && <FooterThreeShop />}
        {checkHeaderShop() && shopHomeData?.template == 4 && <FooterFourShop />}
        {checkHeaderShop() && shopHomeData?.template == 10 && <FooterTen />}
        {checkHeaderShop() && shopHomeData?.template == 11 && <FooterShopEleven />}
        {checkHeaderShop() && shopHomeData?.template == 12 && <FooterShopTweleve />}
        {checkHeaderShopHome() && <FooterFiveShop />}

        {checkHeader() && settingsData?.template == 6 && <FooterUplands />}
        {checkHeader() && settingsData?.template == 7 && <FooterRogers />}
        {checkHeader() && settingsData?.template == 8 && <FooterWheatley />}
        {checkHeader() && settingsData?.template == 9 && <Sec9 />}
        {checkHeader() && settingsData?.template == 10 && <FooterBlack />}
        {checkHeader() && settingsData?.template == 11 && <FooterIhateIroning />}
        {checkHeader() && settingsData?.template == 12 && <FooterTweleve />}
        {checkHeader() && settingsData?.template == 13 && <FooterThirteen />}
      </ScrollToTop>
    </>
  );
}

export default App;
